import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/zthb2G2cBRE">
    <SEO title="Cross Church Unplugged - Holiday on its Head" />
  </Layout>
)

export default SermonPost
